<template>
  <v-btn-toggle rounded>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-on="on"
          v-bind="attrs"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>Refresh</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-on="on"
          v-bind="attrs"
        >
          <v-icon>mdi-share</v-icon>
        </v-btn>
      </template>
      <span>Share</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-on="on"
          v-bind="attrs"
        >
          <v-icon>mdi-flag</v-icon>
        </v-btn>
      </template>
      <span>Report</span>
    </v-tooltip>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'NFTListingSocialActionsBar',
};
</script>
